.item {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.item-info {
  padding-left: 20px;
}

.item-info > button {
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.item-img {
  object-fit: contain;
  width: 180px;
  height: 180px;
}

.item-rating {
  display: flex;
}

.item-title {
  font-size: 17px;
  font-weight: 800;
}
