.basket {
  display: flex;
  padding: 20px;
  background-color: whitesmoke;
  height: max-content;
}

.basket-ad {
  width: 100%;
  margin-bottom: 10px;
}

.basket-title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
