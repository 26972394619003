.login {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}

.login-container {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}

.login-container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login-container > form > h5 {
  margin-bottom: 5px;
}

.login-container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login-container > p {
  margin-top: 15px;
  font-size: 12px;
}

.login-sign-in-button {
  background: #f0c14b;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #f7db95 #9c7e31 #846a29;
}

.login-register-button {
  border-radius: 4px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: darkgray;
}
